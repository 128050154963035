/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import * as React from "react";

const copyToClipboard = async (value: string) => {
    try {
        let copyValue = "";

        if (!navigator.clipboard) {
            throw new Error("Browser don't have support for native clipboard.");
        }

        if (value) {
            copyValue = value;
        }

        await navigator.clipboard.writeText(copyValue);
        console.log(`Claude copied ${value}!`);
    } catch (error) {
        console.warn(error ? (error as string).toString() : "Can't copy.");
    }
};

interface OwnProps {
    content: string;
}

type Props = OwnProps & Mary.theme.ThemeConsumerProps;

export const CodeCopy: React.FunctionComponent<Props> = (props) => {
    const [store, setStore] = React.useState<{copied: boolean}>({copied: false});
    React.useEffect(() => {
        if (store.copied) {
            const timer = setTimeout(() => setStore({
                copied: false,
            }), 3000);
            return () => clearTimeout(timer);
        }
        return;
    }, [store]);
    return (
        <>
            <Mary.atoms.link.Link theme={{palette: Mary.theme.ThemePalette.CONTRAST_TERTIARY}}
                link={{onClick: (_e) => {
                    setStore({
                        copied: false,
                    });
                    copyToClipboard(props.content)
                        .then(() => {
                            setStore({
                                copied: true,
                            });
                        })
                        .catch(e => {
                            console.warn(e);
                        });
                }}} >
                {store.copied ? (<b>Gekopieerd!&nbsp;<Mary.atoms.Icon
                    name={"check"} theme={{paletteState: Mary.theme.ThemePaletteState.SUCCESS}}/></b>)
                    : <b>Kopieer&nbsp;<Mary.atoms.Icon name={"copy"} /></b>}</Mary.atoms.link.Link>
        </>);
};
