/* eslint-disable max-len */
export const I18nAppTranslationsNL = {
    "app.make-a-choice": "Maak een keuze",
    "app.validation.has-value-message": "{{label}} ingevuld.",
    "app.validation.has-value-error-message": "{{label}} moet ingevuld zijn.",
    "app.remote-error": "Claude kan niet aan uw aanvraag voldoen.",
    "app.fatal-remote-error": "Zelfs Claude weet niet wat er mis is!",
    "app.notification": "Notificatie",
    "app.warning": "Waarschuwing",
    "app.07-organisms.form.field.utils.length--error": "Geef voldoende karakters op.",
};
