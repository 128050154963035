import React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ReleaseVersion } from "../../05-atoms/ReleaseVersion";

export const PageWrapper: React.FunctionComponent<{}> = ({children}) => (
    <Mary.base.flex.FlexRow
        theme={{
            palette: Mary.theme.ThemePalette.CONTRAST_SECONDARY,
        }}
    >
        <Mary.base.Container>
            <Mary.base.Div className="scl-b-row">
                <Mary.base.Grid
                    offset={{sm: 2 }}
                    size={{sm: 8}}
                >
                    <Mary.base.Div
                        theme={{
                            padding: {
                                "": { y: 3 },
                                "sm": { y: 4 },
                            },
                        }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Mary.base.BaseConsumer>
                            { ({getTheme}) => (
                                <Mary.base.Logo
                                    variant={Mary.theme.ThemeVariants.BRAND}
                                    theme={getTheme()}
                                    style={{ height: "35px", margin: "0 auto" }}
                                />
                            )}
                        </Mary.base.BaseConsumer>
                    </Mary.base.Div>
                    {children}
                    <Mary.base.Div
                        theme={{
                            padding: {
                                "": { b: 3 },
                                "sm": { b: 4 },
                            },
                        }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Mary.base.BaseConsumer>
                            { ({getTheme}) => (
                                <Mary.base.Logo
                                    variant={getTheme() === Mary.theme.Themes.DUTCHLEASE
                                        ? Mary.theme.ThemeVariants.CONTRAST : Mary.theme.ThemeVariants.BRAND}
                                    theme={Mary.theme.Themes.CLAUDE}
                                    style={{ height: "24px", margin: "0 auto" }}
                                />
                            )}
                        </Mary.base.BaseConsumer>
                        <Mary.base.Div
                            theme={{
                                padding: {
                                    "": { t: 1 },
                                    "sm": { t: 2 },
                                },
                            }}
                        >
                            <ReleaseVersion />
                        </Mary.base.Div>
                    </Mary.base.Div>
                </Mary.base.Grid>
            </Mary.base.Div>
        </Mary.base.Container>
    </Mary.base.flex.FlexRow>
);


