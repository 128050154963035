/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from "axios";
import { FormMaxLeaseLoanObjectResponse } from "./Types";

export const mapFormMaxLeaseLoanResponse =
   (resp: AxiosResponse): FormMaxLeaseLoanObjectResponse => ({
       statusCode: resp?.status,
       ...resp.data as FormMaxLeaseLoanObjectResponse,
   });


