import React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

export const FormWrapper: React.FunctionComponent<{}> = ({children}) => (
    <Mary.base.Div
        theme={{
            padding: {
                "": { b: 3 },
                "sm": { b: 4 },
            },
        }}
    >
        <Mary.molecules.EnvIndicatorBar />
        {children}
    </Mary.base.Div>);


