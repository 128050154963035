import React from "react";
import { connect } from "react-redux";
import { Init } from "./Init";
import { setDocumentTitle } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/utils/Document";
import { FormWrapper } from "../01-sections/FormWrapper";

interface Props {
    asPage: boolean;
    routeProps?: string;
}

class HomeComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.setIframeHeight = this.setIframeHeight.bind(this);
        this.inIframe = this.inIframe.bind(this);
    }

    public componentDidMount() {
        const title = new URL(window.location.href).pathname.includes("/max-loan")
            ? "Max loan" : "Max lease";
        setDocumentTitle(title);
        if(this.inIframe()) {
            window.addEventListener("load", this.setIframeHeight, false);
            window.addEventListener(
                "resize", this.setIframeHeight, false);
            this.setIframeHeight("init");
        }
    }

    public componentWillUnmount() {
        if(this.inIframe()) {
            window.removeEventListener("load", this.setIframeHeight, false);
            window.removeEventListener(
                "resize", this.setIframeHeight, false);
        }
    }

    public componentDidUpdate() {
        const title = new URL(window.location.href).pathname.includes("/max-loan")
            ? "Max loan" : "Max lease";
        setDocumentTitle(title);
    }

    public render() {
        return (
            <FormWrapper>
                <Init asPage={this.props.asPage} routeProps={this.props.routeProps}/>
            </FormWrapper>
        );
    }

    private inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };

    private setIframeHeight(event: any) {
        const height = document.documentElement.scrollHeight;
        console.log(height, "height", event);
        const calcType = this.props.routeProps;
        window.parent.postMessage(
            {
                type: `claude:scalc:${calcType ?? ""}:height`,
                data: {
                    height: height,
                },
            },
            "*",
        );
    }
}

export const Home = connect(
    undefined,
    undefined,
)(HomeComp);


