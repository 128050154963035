/* eslint-disable max-len */
import * as React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ReactDatePickerProps } from "react-datepicker";

interface OwnProps extends Pick<ReactDatePickerProps, "popperPlacement"> {
    label?: string;
    required?: boolean;
    asSummary?: boolean;
    title?: string;
    isRadio?: boolean;
}

/**
 *
 */
type Props = Mary.theme.ThemeConsumerProps & OwnProps & Mary.organisms.FieldCheckValidationProps;

/**
 *
 * @param props
 */
export const FormGroup: React.FunctionComponent<Props> = (props) => {
    const clazzName = Mary.utils.joinClasses(
        props.className || "",
        "scl-o-form__group",
        props.asSummary ? "scl-o-form__group--as-summary" : "",
        props.isRadio ? "scl-o-form__group--radio" : ""
    );

    return (
        <Mary.base.Div
            className={clazzName} transparent
            theme={{ paletteState: Mary.organisms.fieldValidationState(props) }}
        >
            {props.label &&
             <Mary.base.Div className={props.title ? `scl-a-tooltip__wrapper ${props.popperPlacement ? `scl-a-tooltip__wrapper--${props.popperPlacement}`: ""}` : undefined}>
                 <Mary.atoms.Label className={`${props.asSummary ? "scl-a-label__field--as-summary" : ""}`}>
                     {props.label}
                     {props.required ? <span className={"scl-a-label__field--as-required"}>*</span> : ""}
                     <span className={props.title ? "scl-a-tooltip__trigger" : undefined}>
                         {props.title ?
                             <Mary.atoms.Icon name="info" className={"scl-a-tooltip__target"} /> : null}
                     </span>
                     {props.title ?
                         <div className="scl-a-tooltip__container">
                             <div className="scl-a-tooltip">
                                 <span className={"scl-a-tooltip--text"}>{props.title}</span>
                             </div>
                         </div> : null}

                 </Mary.atoms.Label>

             </Mary.base.Div>
            }
            {props.children}
        </Mary.base.Div>
    );
};
