import { SelectOption } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";

export enum FieldType {
    CURRENCY = "currency",
    BOOLEAN = "boolean",
    SELECT = "select",
}

export interface Field {
    id: string;
    title: string;
    description: string;
    fieldType: FieldType;
    required: boolean;
    value?: string | number | boolean;
    options?: SelectOption[];
    dependencies?: {
        [key: string]: any;
    };
    show?: boolean;
}

export interface InitType {
    image?: string;
    title: string;
    description: string;
    fields: {
        [key: string]: Field;
    };
    summary: {
        totalAmountLabel: string;
        disclaimer: string;
        totalAmountValue: null;
    };
    buttonTitle: string;
}


